<template>
  <div class="video-wrapper"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script> 

<style scoped>
</style>